// eslint-disable-next-line
import React from 'react';
import AuthUserContext from './context';
import { withFirebase } from '..';
import tw from 'twin.macro';
import { AlertDialog } from '../../components/alertDialog';
import bigLogo from '../../assets/images/icons/vertical-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { reloadBrowser } from '../../utils/reloadBrowser';

const Container = tw.div`relative min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12 sm:self-center`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold text-center invisible xl:visible`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-gray-100 text-center hidden xl:flex justify-center align-middle`;
const IllustrationImage = tw.img` w-auto object-scale-down p-56`;

interface Props {
  firebase: any;
}

interface State {
  showDialog: boolean;
  error?: any;
}

const needsEmailVerification = (authUser: any) =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData.map((provider: any) => provider.providerId).includes('password');

const withEmailVerification = (Component: any) => {
  class WithEmailVerification extends React.Component<Props, State> {
    constructor(props: any) {
      super(props);

      this.state = { showDialog: false, error: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ showDialog: true }))
        .catch((error: any) => this.setState({ error, showDialog: true }));
    };

    closeModal = () => this.setState({ showDialog: false });

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            needsEmailVerification(authUser) ? (
              <Container>
                <Content>
                  <MainContainer tw="mt-16 md:mt-0">
                    <div tw="flex justify-center">
                      <FontAwesomeIcon icon={faExclamationTriangle} size="5x" color="#2C663C" />
                    </div>

                    <MainContent>
                      <Heading>Please Confirm Your Email to Proceed</Heading>
                      <AlertDialog
                        title={
                          this.state.error ? 'Something Went Wrong...' : 'E-Mail Confirmation sent'
                        }
                        message={
                          this.state.error
                            ? this.state.error.message
                            : 'Check you E-Mails (Spam folder included) for a confirmation E-Mail. Refresh this page once you confirmed your E-Mail'
                        }
                        displayModal={this.state.showDialog}
                        dismissModal={() => reloadBrowser()}
                        mode={'info'}
                        dismissText={'Reload Browser'}
                        dismissAction={() => reloadBrowser()}
                      />

                      <button
                        type="button"
                        onClick={() => this.onSendEmailVerification()}
                        tw="mt-8 inline-flex items-center px-6 py-3 border border-transparent text-base leading-6  rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 active:bg-primary-700 transition ease-in-out duration-150"
                      >
                        <FontAwesomeIcon icon={faEnvelope} size="1x" />
                        <p tw="ml-4">Resend Confirmation E-Mail</p>
                      </button>
                    </MainContent>
                  </MainContainer>
                  <IllustrationContainer>
                    <IllustrationImage src={bigLogo} />
                  </IllustrationContainer>
                </Content>
              </Container>
            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
