// eslint-disable-next-line
import React, { Component } from 'react';
import getUrlParameter from '../utils/getUrlParameter';
import * as yup from 'yup';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../services';
import { AlertDialog } from '../components/alertDialog';
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSave } from '@fortawesome/free-solid-svg-icons';
import Loading from '../utils/loadingAnimation';
import styled from 'styled-components';
import { Form as FormikForm } from 'formik';

const FormContainer = tw.div`w-full flex-1 mt-8`;
const Form = styled(FormikForm)`mx-auto max-w-xs`;
const Title = tw.h1`text-2xl text-center`;
const Description = tw.p`my-4 text-center`;
const MiniDescription = tw.p`my-6 text-sm text-center text-gray-600`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-600 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const ErrorText = tw.p`text-red-400 text-sm italic text-center py-4`;
const SubmitButton = styled.button`
  ${tw`mt-8 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  ${({ disabled }) => (disabled ? tw`cursor-not-allowed` : null)}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

interface SUProps {
  firebase?: any;
  history?: any;
}

interface SUState {
  email: string;
  verifiedEmail: string;
  password: string;
  showDialog: boolean;
  success: boolean;
  error: any;
  loading: boolean;
  showPasswordInputField: boolean;
  resetSuccessful: boolean;
}

const INITIAL_STATE = {
  email: '',
  verifiedEmail: '',
  password: '',
  showDialog: false,
  success: false,
  error: false,
  loading: false,
  showPasswordInputField: false,
  resetSuccessful: false,
};

class ForgotPasswordFormBase extends Component<SUProps, SUState> {
  constructor(props: any) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    this.verifyPasswordReset(getUrlParameter('mode'), getUrlParameter('oobCode'));
  }

  verifyPasswordReset = async (mode: string, actionCode: string) => {
    if (mode === 'resetPassword') {
      this.setState({
        loading: true,
      });
      const userEmail = await this.props.firebase.doPasswordResetValidation(actionCode);
      if (typeof userEmail !== 'string') {
        this.setState({
          ...INITIAL_STATE,
          showDialog: true,
          error: userEmail,
        });
      } else {
        this.setState({
          ...INITIAL_STATE,
          verifiedEmail: userEmail,
          email: userEmail,
          showPasswordInputField: true,
        });
      }
    } else if (mode === 'verifyEmail') {
      await this.props.firebase.doApplyActionCode(getUrlParameter('oobCode'));
      alert('Email Address Confirmed!');
    } else if (mode === 'recoverEmail') {
      this.setState({
        ...INITIAL_STATE,
        showDialog: true,
        success: false,
        error: {
          message:
            'This operation is not supported. Please contact support@truthtotable.com for assistance.',
        },
      });
    }
  };

  performPasswordUpdate = async () => {
    this.setState({
      loading: true,
    });
    const confirmPasswordReset = await this.props.firebase.doPasswordUpdate(
      getUrlParameter('oobCode'),
      this.state.password,
    );
    if (confirmPasswordReset.code)
      this.setState({
        ...INITIAL_STATE,
        showPasswordInputField: true,
        showDialog: true,
        error: confirmPasswordReset,
      });
    else {
      let syncSuccessful: any = null;
      syncSuccessful = await this.props.firebase
        .doHttpsCallable('sync', { email: this.state.verifiedEmail, password: this.state.password })
        .then((result: any) => result.data);
      if (syncSuccessful)
        this.setState({
          loading: false,
          showDialog: true,
          success: true,
          resetSuccessful: true,
          showPasswordInputField: false,
        });
      else
        this.setState({
          ...INITIAL_STATE,
          showDialog: true,
          error: { message: syncSuccessful.message },
          showPasswordInputField: true,
        });
    }
  };

  closeModal = () => this.setState({ ...INITIAL_STATE });

  hideModal = () =>
    this.setState({
      ...INITIAL_STATE,
      showPasswordInputField: true,
    });

  handlePasswordChange = (event: any) => this.setState({ password: event.target.value });

  render() {
    const { showDialog, success, error, loading, resetSuccessful }: any = this.state;
    return (
      <FormContainer>
        <Formik
          initialValues={{ email: '' }}
          onSubmit={async (values: any) => {
            this.setState({ loading: true });
            const { email } = values;
            await this.props.firebase
              .doPasswordReset(email)
              .then(() => {
                this.setState({
                  email: '',
                  showDialog: true,
                  success: true,
                  error: null,
                  loading: false,
                });
              })
              .catch((error: any) => {
                this.setState({ error, showDialog: true, loading: false });
              });
          }}
          validationSchema={yup.object().shape({
            email: yup
              .string()
              .label('Email Address')
              .email('Invalid Email Address')
              .required('Email Address is Required'),
          })}
        >
          {({
            values,
            handleChange,
            errors,
            setFieldTouched,
            touched,
            isValid,
            handleSubmit,
          }: any) => {
            return (
              <>
                <Form>
                  {loading && <Loading />}
                  <AlertDialog
                    title={
                      success && !resetSuccessful
                        ? 'Password Reset Link Sent!'
                        : success && resetSuccessful
                        ? 'Password Reset Success!'
                        : 'Something Went Wrong...'
                    }
                    message={
                      success && !resetSuccessful
                        ? `A password reset link has been emailed to ${values.email}.`
                        : success && resetSuccessful
                        ? 'You may now login to Truth to Table on <a href="https://courses.truthtotable.com/login" target="_blank" style="text-decoration: underline; color:#d7a83d;">Web</a> and <a href="https://truthtotable.com/app" target="_blank" style="text-decoration: underline; color:#d7a83d;">Mobile</a>'
                        : error.message + '\n\nAre you looking for access to "How to Write a Book", "Going Live Simplified", "Message Prep" or "Delivering A Message"? Reset your password for your Influencer Table account here: https://courses.theinfluencertable.com/login/'
                    }
                    displayModal={showDialog}
                    dismissModal={() =>
                      this.state.showPasswordInputField ? this.hideModal() : this.closeModal()
                    }
                    mode={success ? 'success' : 'error'}
                    dismissText={'Okay'}
                    dismissAction={() =>
                      this.state.showPasswordInputField
                        ? this.hideModal()
                        : this.state.resetSuccessful
                        ? window.location.replace('https://account.truthtotable.com')
                        : this.closeModal()
                    }
                  />
                  {!this.state.showPasswordInputField ? (
                    <>
                      <Title>Forgot Your Password?</Title>
                      <Description>
                        Submit your email below to receive a password reset link
                      </Description>
                      <Input
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange('email')}
                        onBlur={() => setFieldTouched('email')}
                        placeholder="Email Address"
                      />
                      {touched.email && errors.email && <ErrorText>{errors.email}</ErrorText>}
                      <SubmitButton disabled={!isValid} onClick={handleSubmit} type="submit">
                        <FontAwesomeIcon icon={faEnvelope} />
                        <span className="text">Reset Password</span>
                      </SubmitButton>
                      <MiniDescription>
                        Looking for access to "How to Write a Book", "Going Live Simplified", "Message Prep" or "Delivering A Message"?<br/><br/>Reset your password for your Influencer Table account here: <a href="https://courses.theinfluencertable.com/login/" rel="noopener noreferrer" target="_blank" tw="text-xs underline font-bold">https://courses.theinfluencertable.com/login/</a>
                      </MiniDescription>
                    </>
                  ) : (
                    <>
                      <Title>Reset Your Password</Title>
                      <Input
                        tw="mt-8"
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        placeholder="New Password"
                      />
                      <SubmitButton
                        disabled={this.state.password === ''}
                        onClick={this.performPasswordUpdate}
                        type="submit"
                      >
                        <FontAwesomeIcon icon={faSave} />
                        <span className="text">Save Password</span>
                      </SubmitButton>
                    </>
                  )}
                </Form>
              </>
            );
          }}
        </Formik>
      </FormContainer>
    );
  }
}

const ForgotPasswordForm = withRouter(withFirebase(ForgotPasswordFormBase));

export { ForgotPasswordForm };
