import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/performance';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  appId: process.env.REACT_APP_WEB_APP_ID,
};

class Firebase {
  auth: app.auth.Auth | any;
  functions: app.functions.Functions;
  performance: app.performance.Performance;

  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.functions = app.functions();
    this.performance = app.performance();
  }

  doHttpsCallable = async (functionName: string, args?: any) => {
    if (args) {
      const functionWithArgs = this.functions.httpsCallable(functionName);
      return await functionWithArgs(args);
    } else this.functions.httpsCallable(functionName);
  };

  doPasswordReset = (email: string) =>
    this.auth.sendPasswordResetEmail(email).catch((error: any) => error);

  doPasswordResetValidation = async (actionCode: string) =>
    this.auth
      .verifyPasswordResetCode(actionCode)
      .then((email: any) => email)
      .catch((error: any) => error);

  doPasswordUpdate = async (actionCode: string, newPassword: string) =>
    this.auth
      .confirmPasswordReset(actionCode, newPassword)
      .then(() => true)
      .catch((error: any) => error);

  doApplyActionCode = async (actionCode: string) =>
    this.auth
      .applyActionCode(actionCode)
      .then((resp: any) => true)
      .catch((error: any) => error);

  onAuthUserListener = (next: any, fallback: any) =>
    this.auth.onAuthStateChanged(async (authUser: any) => {
      if (authUser) {
        next();
      } else {
        fallback();
      }
    });
}

export default Firebase;
