// eslint-disable-next-line
import React from 'react';

const FirebaseContext: any = React.createContext(null);

/* eslint-disable react/display-name */
export const withFirebase: any = (Component: any) => (props: any): JSX.Element => (
  <FirebaseContext.Consumer>
    {(firebase: any) => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export default FirebaseContext;
