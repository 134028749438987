import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../components/errorBoundary';
import ForgotPasswordPage from '../pages/ForgotPassword';
import { Splash } from '../pages/Splash';
import * as ROUTES from '../constants/routes';
import { withAuthentication } from '../services/Session';
import tw from 'twin.macro';

const AppContainer = tw.div`text-secondary-700`;

const App = () => {
  return (
    <ErrorBoundary>
      <AppContainer>
          <Router>
            <div tw="flex">
              <div tw="flex flex-col w-0 flex-1">
                <Switch>
                  <Route exact path="/" component={ForgotPasswordPage} />
                  <Route exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPasswordPage} />
                  <Route default component={Splash} />
                </Switch>
              </div>
            </div>
          </Router>
      </AppContainer>
    </ErrorBoundary>
  );
}

export default withAuthentication(App)
