// eslint-disable-next-line
import React from 'react';
import * as Sentry from '@sentry/react';
import { Splash } from '../pages/Splash';

class ErrorBoundary extends React.Component {
  state = { error: null, info: null };

  constructor(props: any) {
    super(props);
    if (['production', 'staging'].indexOf(process.env.NODE_ENV as any) !== -1)
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_KEY,
        release: `admin@${1}`,
        environment: process.env.REACT_APP_ENV,
        debug: process.env.NODE_ENV === 'development',
        beforeSend(event: any) {
          if (event.exception)
            Sentry.showReportDialog({ eventId: event.event_id });
          return event;
        },
      });
  }

  componentDidCatch(error: any, info: any): void {
    this.setState({ error, info });
    if (['production', 'staging'].indexOf(process.env.NODE_ENV as any) !== -1) {
      console.error(info);
      console.error(error);
    } else {
      Sentry.configureScope((scope: any) => {
        Object.keys(info).forEach((key) => {
          scope.setExtra(key, info[key]);
        });
      });
      Sentry.captureException({ error, info });
    }
  }

  render(): any {
    return this.state.error ? <Splash errorState={this.state} /> : this.props.children;
  }
}

export default ErrorBoundary;
